import { constructScript, removeScript } from "@/utils/functions/scripts"
import { useEffect } from "react"

export const MicroLocalBusiness = ({ hostname }) => {
  const { business, domain } = hostname
  const { thumb, address, alias, webUrl, mapsUrl, schedules, geoCoordinates } = business
  const scheduleBusiness = schedules[0] || []
  const businessAddress = address[0] || []
  const businessCoordinates = JSON.parse(geoCoordinates) || {}
  const distrState = businessAddress?.district?.name ?? businessAddress?.district ?? businessAddress?.state?.name ?? businessAddress?.state ?? ``
  const sameAs = undefined
  const data = JSON.stringify({
    "@context": `http://www.schema.org`,
    "@type": `LocalBusiness`,
    name: alias,
    url: `${webUrl}${domain}`,
    priceRange: `5-1000€`,

    telephone: `${businessAddress.phonePrefix}${businessAddress.phone}`,
    image: `${thumb}${business.faviconUrl}`,
    sameAs,
    address: {
      "@type": `PostalAddress`,
      streetAddress: businessAddress.street,
      addressLocality: businessAddress.city ? businessAddress.city?.name : businessAddress.town,
      addressRegion: distrState,
      postalCode: businessAddress.zip,
      addressCountry: businessAddress.country?.code,
    },
    hasMap: mapsUrl,
    openingHoursSpecification: [
      scheduleBusiness.days.filter(x => x.closed === 0).map(y => {
        return {
          "@type": `OpeningHoursSpecification`,
          dayOfWeek: y.name,
          opens: y.schedule[0].start.substring(0, 5),
          closes: y.schedule[y.schedule.length - 1].end.substring(0, 5)
        }
      })
    ],
    contactPoint: {
      "@type": `ContactPoint`,
      telephone: `+${businessAddress.phonePrefix}${businessAddress.phone}`,
      contactType: `sales`,
    },
    geo: {
      "@type": `GeoCoordinates`,
      latitude: businessCoordinates.latitude,
      longitude: businessCoordinates.longitude,
    },
  })

  useEffect(() => {
    const name = `"micro-local-business`
    constructScript(`script`, name, data)

    return () => {
      removeScript(name)
    }
  }, [data])

  return null
}
