'use client'
import { ProductCardSearch } from "@/app/(web)/_components/card/ProductCardSearch"
import LoaderBar from "@/components/tailwind/LoaderBar"
import { SearchContext } from "@/context/search"
import { handleKeyRandom } from "@/utils/functions"
import { memo, useContext, useEffect, useRef } from "react"

export const Catalogue = memo(props => {
  const {
    loading, mobile, texts, relateds, origin, currency, plus, page, labels, hostname
  } = props

  const { toggleSearchClose } = useContext(SearchContext)
  const { platform, colors, domain, events } = hostname

  const ref = useRef(null)

  function handleScroll() {
    const searchDiv = document.querySelector(`#box-search`)
    if (searchDiv.scrollTop + searchDiv.clientHeight === searchDiv.scrollHeight) {
      // page < totalPage && setPages(x => x + 1)
    }
  }

  useEffect(() => {
    ref.current.scrollTop = 0
  }, [ page, relateds ])

  return (
    <div
      id="box-search"
      ref={ref}
      className="flex flex-wrap"
      style={{
        margin: `auto`,
        maxHeight: `${!mobile ? 65 : 58}dvh`,
        minHeight: `${!mobile ? 65 : 58}dvh`,
        alignSelf: `baseline`,
        justifyContent: `space-evenly`,
        padding: `6px ${!mobile ? 40 : 4}px`,
        overflowY: `auto`,
      }}
    >
      <div style={{
        width: `100%`,
        height: 10
      }} >
        {loading && (
          <LoaderBar color={colors.tildMenu}/>
        )}
      </div>
      {relateds?.length > 0 &&
        relateds?.map((elem, idx) => {
          return (
            <ProductCardSearch
              key={handleKeyRandom(`product-search`, idx)}
              {...{
                element: elem,
                mobile,
                plus,
                domain,
                image: elem.image,
                url: `/${elem.url}`,
                price: elem.cost,
                pvp: elem.pvp,
                discount: elem.discount,
                imgalt: elem.imgalt,
                prodId: elem.prod_id,
                varId: elem.var_id,
                title: elem.title,
                variants: elem.variants,
                categories: elem.categories,
                brand: elem.brand,
                money: texts.money,
                score: elem.score,
                events,
                product: elem,
                web: texts.web,
                login: null,
                catalogue: true,
                show: true,
                origin,
                currency,
                labels,
                hostname,
                colors,
                toggleSearchClose
              }}
            />
          )
        })}
    </div>
  )
})
