'use client'
import { DropDownArrows } from "@/app/(web)/_components/basket/subcomponent/components/subcomponents/DropDownArrows"
import { MenuContext, MobileContext } from "@/context"
import { LoadingContext } from "@/context/tools/loading"
import { useMediaQuerys } from "@/hooks/utils/useMediaQuerys"
import { cleanPath } from "@/utils/functions"
import Link from "next/link"
import { usePathname } from "next/navigation"
import {
  Fragment,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"

const SubMenu = ({
  menu, level3, colors, lev, handleChange, expand, expandedCategory, expandedSubCategory, path, icon, host, mobile, ...props
}) => {
  const level = useMemo(() => lev, [])
  const { xsQuery } = useMediaQuerys()
  const { toggleOpenSwipMobile } = useContext(MobileContext)
  const currentPath = usePathname()
  const [ change, setChange ] = useState(0)
  const {
    level3: dataLevel
  } = useContext(MenuContext)
  const { modules } = useContext(LoadingContext)
  const handleOpen = async x => {
    await setChange(b => b === x.info.id ? null : x.info.id)
    await handleChange(x.info.id, level, change === x.info.id)
  }
  return (
    <div className={level === 2 ? `bg-neutral-50` : `bg-neutral-100`}>
      {menu.map((x, i) => {
        const selected = currentPath === cleanPath(x.path)
        const textColor = selected ? colors.tild : colors.color

        return <Fragment key={`menu_level_${level}_${i}`}>
          <div className={`flex justify-between items-center text-black hover:bg-neutral-200 ${level === 2 ? `pl-10` : `pl-14`}`}>
            <Link prefetch={false} href={host + x.path}
              passHref shallow={false}
              scroll={true} style={{ textDecoration: `none`, width: `100%` }}
              onClick={async() => {
                await modules.handleLinkLoading(true)
                if (xsQuery && level === 2) {
                  handleOpen(x)
                }
              }}>
              <div id="submenu_link"
                onClick={() => !xsQuery && [ 2, 3 ].includes(level) && toggleOpenSwipMobile(false)}
                className={`flex items-center text-sm ${expandedCategory === x.info.id ? `font-bold` : `font-medium`} capitalize overflow-hidden text-wrap py-2 text-left justify-start`}>
                <div id="link-text" className="w-[85%] px-2" hidden={!open} style={{ color: textColor }}>
                  {x.info.h1}
                </div>

                { !mobile && open && level === 2 && !!x.info?.children && <div className="w-10 float-end  flex cursor-pointer justify-end items-center px-3"> {
                  <DropDownArrows open={change === x.info.id} />
                }</div>}

              </div>
            </Link>
            { mobile && open && level === 2 && !!x.info?.children && <div className="w-10 flex cursor-pointer justify-end px-3" onClick={e => handleOpen(x)}> {
              <DropDownArrows open={change === x.info.id} />
            }</div>}
          </div>
          {change === x.info.id && !!dataLevel?.length > 0 &&
          <SubMenu
            menu={dataLevel}
            lev={level + 1}
            expand={level < 3 ? expandedCategory : expandedSubCategory}
            handleChange={handleChange}
            expandedCategory={expandedCategory}
            expandedSubCategory={expandedSubCategory}
            path={x.info.url}
            {...props}
            {...{ level3: [], colors, host }}
          />}
        </Fragment>
      })

      }
    </div>
  )
}
export default memo(SubMenu)
